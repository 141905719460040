import * as actionTypes from '../../actionTypes';

const initialState = {
    showExtra: false,
};

const modalWrapperReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.SHOW_MODAL_EXTRA:
            return {
                showExtra: true,
                ...payload
            };
        case actionTypes.HIDE_MODAL_EXTRA:
            return {}
        default:
            return state;
    }
};

export default modalWrapperReducer;
