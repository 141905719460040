import * as actionTypes from '../../actionTypes';

const initialState = {
    show: false,
};

// update state/data untuk di kirim ke komponen
const modalWrapperReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case actionTypes.SHOW_MODAL:
            return {
                show: true,
                ...payload
            };
        case actionTypes.HIDE_MODAL:
            return { }
        default:
            return state;
    }
};

export default modalWrapperReducer;
