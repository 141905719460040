import { combineReducers } from "redux"
import loginReducer from './loginReducer'
import registerReducer from './registerReducer'
import modalReducer from './modalReducer'
import materiReducer from './materiReducer'
import backdropReducer from './backdropReducer'
import extraModalReducer from './extraModalReducer'
import serviceReducer from './serviceReducer'

const rootReducer = combineReducers({
  loginUser: loginReducer,
  registerUser: registerReducer,
  modalReducer: modalReducer,
  materiReducer: materiReducer,
  backdropReducer: backdropReducer,
  extraModalReducer: extraModalReducer,
  serviceReducer: serviceReducer,
})

export default rootReducer
