//LOGIN REDUCER
// export const FETCH_USERS = "FETCH_USERS"
export const POST_LOGIN = "POST_LOGIN"
export const SET_VALUES_LOGIN = "SET_VALUES_LOGIN"
export const POST_RESET_PASSWORD = "POST_RESET_PASSWORD"
export const RESET_PASSWORD = "RESET_PASSWORD"

//REGISTER REDUCER
export const POST_REGISTER = "POST_REGISTER"
export const SET_VALUES_REGISTER = "SET_VALUES_REGISTER"

//MODAL REDUCER
export const SHOW_MODAL = "SHOW_MODAL"
export const SHOW_MODAL_EXTRA = "SHOW_MODAL_EXTRA"
export const HIDE_MODAL = "HIDE_MODAL"
export const HIDE_MODAL_EXTRA = "HIDE_MODAL_EXTRA"
export const showModalAction = "showModalAction"
export const showExtraModalAction = "showExtraModalAction"
export const hideModalAction = "hideModalAction"
export const hideExtraModalAction = "hideExtraModalAction"

//MATERI REDUCER
export const SET_HASHED = "SET_HASHED"
export const SET_VALUE_MATERI = "SET_VALUE_MATERI"
export const SET_DETAIL_MATERI = "SET_DETAIL_MATERI"
export const ANSWER_QUIZ = "ANSWER_QUIZ"
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP"
export const SET_COMPLETED = "SET_COMPLETED"
export const SET_STEP_FROM_API = "SET_STEP_FROM_API"
export const SET_DETAIL_WEBINAR_COURSE = "SET_DETAIL_WEBINAR_COURSE"
export const SET_DETAIL_WEBINAR_PUBLIC = "SET_DETAIL_WEBINAR_PUBLIC"
export const SET_CERTIFICATE = "SET_CERTIFICATE"
export const SET_COURSE_LIST = "SET_COURSE_LIST"
export const SET_COURSE_DETAIL = "SET_COURSE_DETAIL"
export const FILTER_COURSE = "FILTER_COURSE"
export const SEARCH_MATERI = "SEARCH_MATERI"
export const SET_REVIEW = "SET_REVIEW"
export const SET_CERTIFICATE_IMAGE = "SET_CERTIFICATE_IMAGE"
export const SET_DONE = "SET_DONE"
export const SET_REF = "SET_REF"
export const SET_UJIAN = "SET_UJIAN"
export const SET_PRE_TEST = "SET_PRE_TEST" //new version
export const SET_POST_TEST = "SET_POST_TEST" //new version
export const SET_POST_TEST_DATA = "SET_POST_TEST_DATA" //new version

//BACKDROP REDUCER
export const SHOW_BACKDROP = "SHOW_BACKDROP";
export const HIDE_BACKDROP = "HIDE_BACKDROP";

//ALL COURSES
export const SET_ALL_COURSES = "SET_ALL_COURSES";
export const SEARCH_VALUE = "SEARCH_VALUE";

// SERVICE REDUCER
export const SET_SERVICE_VALUE = "SET_SERVICE_VALUE";
export const SET_FAQ = "SET_FAQ";
export const SET_USER_DETAIL = "SET_USER_DETAIL";
export const SET_PLATFORM = "SET_PLATFORM";
export const SET_REFERENCE = "SET_REFERENCE";
