import * as actionTypes from '../../actionTypes';

const initialState = {
  videoLink: '',
  selectId: '',
  onLearning: false,
  fullMateri: [],
  detailMateri: {},
  fullData: [],
  activeStep: 0,
  completed: new Set(),
  webinarPublic: {},
  webinarCourse: {},
  certificate: {},
  courseList: [],
  reviewData: false,
  apiStep: '',
  certificateImage: '',
  allCourseList: [],
  done: false,
  refference: [],
  ujian: false,
  isNewCourse: 0,
  searchValue: '',
  reference:[],
  pretest:false,
  postTest:[],
  data:null,

}

const materiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_HASHED:
      return { ...state, videoLink: action.videoLink, selectId: action.selectId }
    case actionTypes.SET_VALUE_MATERI:
      return { ...state, fullData: action.payload.data, fullMateri: action.payload.data.course }
    case actionTypes.SET_DETAIL_MATERI:
      return {
        ...state,
        detailMateri: action.payload,
        selectId: action.payload.material.id,
        apiStep: action.payload.step,
        isNewCourse: action.payload.isNewCourse,
        type: action.payload.course.type
      }
    case actionTypes.SET_ACTIVE_STEP:
      return { ...state, activeStep: action.activeStep }
    case actionTypes.SET_COMPLETED:
      return { ...state, completed: action.completed }
    case actionTypes.SET_STEP_FROM_API:
      return { ...state, activeStep: action.step, completed: action.newSet }
    case actionTypes.SET_DETAIL_WEBINAR_PUBLIC:
      return { ...state, webinarPublic: action.payload }
    // case actionTypes.SET_DETAIL_WEBINAR_COURSE:
    //   return { ...state, webinarCourse: action.payload }
    case actionTypes.SET_CERTIFICATE:
      return { ...state, certificate: action.payload }
    case actionTypes.SET_COURSE_LIST:
      return { ...state, courseList: action.payload }
    case actionTypes.FILTER_COURSE:
      return { ...state, courseList: action.selectCourse }
    case actionTypes.SET_REVIEW:
      return { ...state, reviewData: action.payload }
    case actionTypes.SET_CERTIFICATE_IMAGE:
      return { ...state, certificateImage: action.payload }
    case actionTypes.SET_DONE:
      return { ...state, done: action.done }
    case actionTypes.SET_REF:
      return { ...state, refference: action.refference }
    case actionTypes.SET_UJIAN:
      return { ...state, ujian: action.payload }
    case actionTypes.SET_ALL_COURSES:
      return { ...state, allCourseList: action.payload.body }
    case actionTypes.SEARCH_VALUE:
      return { ...state, searchValue: action.payload }
    case actionTypes.SET_REFERENCE: //new version
          return { ...state, reference :action.payload }
    case actionTypes.SET_PRE_TEST: //new version
          return { ...state, pretest :action.payload }
    case actionTypes.SET_POST_TEST: //new version
          return { ...state, postTest :action.payload }
    case actionTypes.SET_POST_TEST_DATA: //new version
          return { ...state, data :action.payload }
    default:
      return state
  }
}

export default materiReducer;