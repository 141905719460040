import * as actionTypes from '../../actionTypes';

const initialState = {
  userLogin: {
    username: '',
    password: '',
    showPassword: false,
  },
  userResetPassword: {
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false,
  }
}
  
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POST_LOGIN:
      return { ...state}
    case actionTypes.POST_RESET_PASSWORD:
      return { ...state}
    case actionTypes.SET_VALUES_LOGIN:
      return { ...state, userLogin: {
        ...state.userLogin,
        [action.prop]: action.value,
      }}
    case actionTypes.RESET_PASSWORD:
    return { ...state, userResetPassword: {
      ...state.userResetPassword,
      [action.prop]: action.value,
    }}
    default:
      return state
    }
  }
  
  export default loginReducer;
  