import * as actionTypes from '../../actionTypes';
const initialState = {
    faqData: null,
    user:[],
    platform:[],

}


const serviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FAQ:
            return { ...state, faqData: action.payload }
        case actionTypes.SET_USER_DETAIL:
            return { ...state, user: action.payload }
        case actionTypes.SET_PLATFORM:
            return { ...state, platform :action.payload }
        
        default:
            return state
    }

}

export default serviceReducer;
  