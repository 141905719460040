import * as actionTypes from '../../actionTypes';

const initialState = {
    show: false
};

const modalWrapperReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case actionTypes.SHOW_BACKDROP:
            return {
                show: true,
            };
        case actionTypes.HIDE_BACKDROP:
            return {
                show: false,
            };
        default:
            return state;   
    }
};

export default modalWrapperReducer;
